import { graphql } from 'gatsby';
import React from 'react';

import { Projects } from '../components/Projects/Projects';
import { Root } from '../components/Root';
import { ProjectType } from '../types';

export const query = graphql`
  query projects {
    projects: allSanityProject(
      limit: 10
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      edges {
        node {
          Year
          Name
          Tags
          Client
          VideoUrl
          Slug
          Thumb {
            asset {
              fluid(maxWidth: 1200) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

const projects = ({ data }) => {
  const theProjects: ProjectType[] = data.projects.edges.map(item => {
    return item.node;
  });

  return (
    <Root includeFooter={false}>
      <Projects projects={theProjects} />
    </Root>
  );
};

export default projects;
