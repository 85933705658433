import React from 'react';

import { ProjectType } from '../types';

import { Footer } from './Home/Footer';
import { ProjectList } from './Home/ProjectList';

type Props = {
  projects: ProjectType[];
};

export function MobileGallery({ projects }: Props) {
  return (
    <>
      <ProjectList title="Projects" projects={projects} />
      <Footer />
    </>
  );
}
