import React, { useEffect, useState } from 'react';

import { DesktopWidth } from '../../constants';
import { ProjectType } from '../../types';
import { inBrowser } from '../../utils/inBrowser';
import { DesktopGallery } from '../DesktopGallery';
import { MobileGallery } from '../MobileGallery';

type Props = {
  projects: ProjectType[];
};

enum ViewTypes {
  Mobile = 1,
  Desktop = 2
}

export function Projects({ projects }: Props) {
  const [view, setView] = useState<ViewTypes>(ViewTypes.Desktop);

  function resizeWindow() {
    const windowSize = inBrowser() ? window.innerWidth : 0;

    if (windowSize <= DesktopWidth) {
      setView(ViewTypes.Mobile);
    } else {
      setView(ViewTypes.Desktop);
    }
  }

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  return (
    <>
      {view === ViewTypes.Mobile && <MobileGallery projects={projects} />}
      {view === ViewTypes.Desktop && <DesktopGallery projects={projects} />}
    </>
  );
}
