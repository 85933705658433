import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import Slider from 'react-slick';

import { Breakpoint, ProjectType } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';
import { inBrowser } from '../utils/inBrowser';

import { BackgroundLines } from './BackgroundLines';
import { Contact } from './Contact/Contact';
import { ClientBox } from './Projects/ClientBox';

type Props = {
  projects: ProjectType[];
};

export function DesktopGallery({ projects }: Props) {
  const [active, setActive] = useState<number>(0);
  const slider = useRef<any>();

  let size = 3.92;
  if (inBrowser() && window.innerHeight > 768) {
    size = 3.8;
  }

  const settings = {
    dots: false,
    slidesToShow: size,
    slidesToScroll: 1,
    swideToSlide: true,
    vertical: true,
    verticalSwipe: true,
    infinite: true,
    centerMode: true,
    beforeChange: switchSlide,
    focusOnSelect: true,
    accessibility: true,
    touchMove: true,
    mobileFirst: true,
    waitForAnimate: true
  };

  function switchSlide(current, next) {
    setActive(next);
  }

  function handleScroll(event: WheelEvent) {
    if (event.deltaY > 0) {
      slider.current!.slickNext();
    } else {
      slider.current!.slickPrev();
    }
  }

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll]);

  const p = projects[active];

  return (
    <>
      <ClientContainer>
        <ClientBox direction="right" slug={p.Slug} client={p.Client} />
      </ClientContainer>
      <Container>
        <StyledSlider ref={slider} {...settings}>
          {projects.map((project, index) => {
            const link = project.Slug === p.Slug;

            return (
              <Fragment key={index}>
                {link && (
                  <Link to={`/project/${project.Slug}`}>
                    <Image
                      fluid={project.Thumb.asset.fluid}
                      alt={project.Slug}
                    />
                  </Link>
                )}
                {!link && (
                  <Image fluid={project.Thumb.asset.fluid} alt={project.Slug} />
                )}
              </Fragment>
            );
          })}
        </StyledSlider>
      </Container>
      <Contact />
      <BackgroundLines shortVersion={true} />
    </>
  );
}

const Container = styled.div`
  position: absolute;
  left: 50vw;
  width: 50vw;
  height: 100vh;

  /* slick slide overrides */
  .slick-vertical .slick-slide {
    border: 0px !important;
  }

  cursor: pointer;

  ${forBreakpoint(
  Breakpoint.TabletLandscape,
  css`
      clip-path: polygon(40% 0%, 100% 0, 100% 100%, 0% 100%);
    `
)}
  ${forBreakpoint(
  Breakpoint.Desktop,
  css`
      clip-path: polygon(28% 0%, 100% 0, 100% 100%, 0% 100%);
    `
)}
    ${forBreakpoint(
  Breakpoint.Wide,
  css`
        clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
      `
)}
    ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
        clip-path: polygon(30% 0%, 100% 0, 100% 100%, 0% 100%);
      `
)}
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled(Img)`
  width: 65vw;
  height: 65vh;
`;

const ClientContainer = styled.div`
  position: absolute;
  width: 30vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;
