import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Text } from '../UI/Text';

export function Contact() {
  return (
    <StyledContact>
      <StyledLink href="tel:+32492633245">
        <StyledPhoneNumber mode="gray">+32 492 63 32 45</StyledPhoneNumber>
      </StyledLink>
      <StyledLink href="mailto:email@arnopoppelaars.be">
        <Text mode="gray">email@arnopoppelaars.be</Text>
      </StyledLink>
    </StyledContact>
  );
}

const StyledContact = styled.div`
  display: none;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      display: inline-block;
      position: absolute;
      bottom: 1.5rem;
      left: 1.5rem;
    `
  )}

${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      bottom: 5rem;
      left: 5rem;
    `
  )};

`;

const StyledPhoneNumber = styled(Text)`
  display: block;
  letter-spacing: 0.8px;
  margin-bottom: -0.4rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
`;
